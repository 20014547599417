<template>
  <a-layout class="custom-layout">
    <a-layout-sider class="custom-sider">
      <system-menu></system-menu>
    </a-layout-sider>
    <a-layout-content class="custom-content auto" style="position:relative;">
      <a-spin :spinning="loading">
        <div class="topsubmenu auto">
          <a-radio-group default-value="5" size="small" @change="changeSubmenu" button-style="solid">
            <a-radio-button value="1">
              账号安全策略
            </a-radio-button>
            <a-radio-button value="4">
              Syslog配置
            </a-radio-button>
            <a-radio-button value="5">
              属性配置
            </a-radio-button>
            <a-radio-button value="2">
              基本配置
            </a-radio-button>
            <a-radio-button value="3">
              系统准入策略
            </a-radio-button>
          </a-radio-group>
        </div>
        <div class="content-title auto">
          <div class="coustom-title fl">属性配置</div>
        </div>
          
        <div class="coustom-title-mes auto">
          <a-icon type="info-circle" theme="filled" style="color:#226FBB;margin-right: 10px;" />当前属性 {{total}} 项
        </div>
        <div class="content-bar auto">
          <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addProperty"> 添加属性 </a-button>
              <a-input type="text" v-model="filter.key" size="small" placeholder="请输入key" class="m-r-5 fl" style="width:160px;" allowClear></a-input>
              <a-button type="primary" size="small" class="fl" @click="searchHandle">查找</a-button>
            </div>
          </custom-page>
        </div>
        <div class="content-table auto">
          <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tabledata" size="middle" :pagination="false">
            <div slot="status" slot-scope="text, record">
              {{record.status == '1' ? '是' : '否'}}
            </div>
            <div slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" class="m-r-5" @click="editHandle(record)">编辑</a-button>
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="deleteHandle(record)"
              >
                <a-button type="primary" size="small" class="bgred borred">删除</a-button>
              </a-popconfirm>
            </div>
          </a-table>
        </div>
        <div class="content-bar auto">
          <custom-page :total="total" @getPageMes="getPageMes" :page="filter.current_page" :limit="filter.page_size">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addProperty"> 添加属性 </a-button>
              <a-input type="text" v-model="filter.key" size="small" placeholder="请输入key" class="m-r-5 fl" style="width:160px;" allowClear></a-input>
              <a-button type="primary" size="small" class="fl" @click="searchHandle">查找</a-button>
            </div>
          </custom-page>
        </div>
      </a-spin>
    </a-layout-content>

    <a-modal v-model="addVisible" :title="isAdd ? '添加' : '编辑'" @cancel="addCancel" @ok="addSubmit" width="600px">
      <a-form-model
        ref="addForm"
        :model="form"
        :rules="formRules"
        :label-col="{span: 3}"
        :wrapper-col="{span: 21}"
        class="ant-form-space-20"
      >
        <a-form-model-item label="key" prop="key">
          <a-input v-model="form.key" placeholder="请输入" size="small" :disabled="!!!isAdd"></a-input>
        </a-form-model-item>
        <a-form-model-item label="value" prop="value">
          <a-textarea v-model="form.value" placeholder="请输入" size="small" style="min-height: 112px;"></a-textarea>
        </a-form-model-item>
        <a-form-model-item label="status" prop="status">
          <a-radio-group v-model="form.status">
            <a-radio value="1">
              是
            </a-radio>
            <a-radio value="0">
              否
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
      <template slot="footer">
        <a-button key="back" @click="addCancel">
          取 消
        </a-button>
        <a-button key="submit" type="primary" @click="addSubmit">
          确 定
        </a-button>
      </template>
    </a-modal>

  </a-layout>
</template>

<script>
import { System } from '@/model'
export default {
  data () {
    return {
      loading: false,
      tabledata: [],
      total: 0,
      filter: {
        key: '',
        value: '',
        status: '',
        current_page: 1,
        page_size: 10
      },
      tablecolumns: [
        {
          title: 'key',
          dataIndex: 'key',
          customCell: () => {
            return {
              style: {
                'width': '240px',
              }
            }
          }
        },
        {
          title: 'value',
          dataIndex: 'value'
        },
        {
          title: 'status',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          customCell: () => {
            return {
              style: {
                'width': '68px',
              }
            }
          }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          customCell: () => {
            return {
              style: {
                'width': '130px',
              }
            }
          }
        }
      ],
      form: {
        key: '',
        value: '',
        status: '1'
      },
      formRules: {
        key: [{ required: true, message: 'key不能为空', trigger: 'blur' }],
        value: [{ required: true, message: 'value不能为空', trigger: 'blur' }]
      },
      addVisible: false,
      isAdd: false
    }
  },
  methods: {
    changeSubmenu (e) {
      var value = Number(e.target.value)
      console.log('value', value)
      switch (value) {
        case 1:
          this.$router.push('/system/configuration/index');
          break;
        case 2:
          this.$router.push('/system/configuration/basic');
          break;
        case 3:
          this.$router.push('/system/configuration/accesspolicy');
          break;
        case 4:
          this.$router.push('/system/configuration/syslogsetting');
          break;
        case 5:
          this.$router.push('/system/configuration/property');
          break;
        default:
          break;
      }
    },
    editHandle (row) {
      this.isAdd = false
      this.form = JSON.parse(JSON.stringify(row))
      this.form['status'] = String(this.form['status'])
      this.addVisible = true
    },
    async deleteHandle (row) {
      this.loading = true
      var newForm = JSON.parse(JSON.stringify(row))
      newForm['status'] = Number(newForm['status'])
      delete newForm['createdDate']
      let res = await System.deleteSystemProperty([newForm])
      this.loading = false
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getSystemProperty()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    addProperty () {
      this.isAdd = true
      this.addVisible = true
    },
    addCancel () {
      this.form = {
        key: '',
        value: '',
        status: '1'
      }
      this.$refs['addForm'].resetFields()
      this.addVisible = false
    },
    async addSubmit () {
      this.loading = true
      var newForm = JSON.parse(JSON.stringify(this.form))
      newForm['status'] = Number(newForm['status'])
      let res = null
      if (this.isAdd) {
        res = await System.postSystemProperty([newForm])
      } else {
        delete newForm['createdDate']
        res = await System.putSystemProperty([newForm])
      }
      this.loading = false
      if (!res['code']) {
        this.$message.success('操作成功！')
        this.getSystemProperty()
        this.addCancel()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    searchHandle () {
      this.filter.current_page = 1
      this.getSystemProperty()
    },
    getPageMes (obj) {
      this.filter.current_page = obj.pageNumber
      this.filter.page_size = obj.pageSize
      this.getSystemProperty()
    },
    async getSystemProperty () {
      this.loading = true
      let newFilter = JSON.parse(JSON.stringify(this.filter))
      newFilter['key'] = '%' + newFilter['key'] + '%'
      newFilter['value'] = '%' + newFilter['value'] + '%'
      let res = await System.getSystemProperty(newFilter)
      this.loading = false
      if (!res['code']) {
        this.tabledata = res['data']
        this.total = res['total']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
  },
  created () {
    this.getSystemProperty()
  },
  mounted () {
  }
};
</script>

<style scoped>
.content-bar {
  padding: 8px 0;
}
.log-page {
  padding: 20px 0;
}

.roles_item {
  display: inline-block;
  width: 140px;
  /*height: 20px;*/
  line-height: 30px;
  margin-bottom: -10px;
  overflow: hidden;
}

.current_roles_box {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}

.topsubmenu {
  padding-bottom: 0;
}
.coustom-title {
  color: rgba(0,0,0,0.85);
}
</style>
